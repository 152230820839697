import React from "react";
import { hydrateRoot } from "react-dom/client";
import { YScroll } from "./components/tools/restoreScroll/yScroll";
import { CustomRouter } from "./components/tools/restoreScroll/customRouter";
import { CookiesProvider } from "react-cookie";
import reportWebVitals from "./reportWebVitals";

import App from "./App";

import "./assets/scss/costum.scss";
import "./assets/css/resetCSS.css";
import "./assets/css/styles.css";

hydrateRoot(
  document.getElementById("root"),
  <React.StrictMode>
    <CustomRouter>
      <YScroll>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </YScroll>
    </CustomRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
